<template>
  <v-container fluid class="pa-0">
    <v-autocomplete
      v-if="!advanced"
      autocomplete="off"
      prepend-icon="mdi-magnify"
      :label="$t('tag.decoder')"
      v-model="currentDecoder"
      :items="decoders"
      item-text="name"
      item-value="key"
    ></v-autocomplete>

    <selection-sheet-container
      v-else
      v-model="currentDecoder"
      :title="$t('tag.decoder')"
      :items="decoders"
      item-key="key"
      font-size=".75rem"
      icon="mdi-code-tags"
      :filter-keys="['name', 'key']"
    />
  </v-container>
</template>

<script>
import DecoderHelper from "@/_helpers/DecoderHelper";
import SelectionSheetContainer from "@/components/common/SelectionSheetContainer";
import { mapActions, mapState } from "vuex";

export default {
  name: "DecoderSelect",

  props: { decoder: { default: null }, advanced: { default: false } },

  components: { SelectionSheetContainer },

  model: {
    prop: "decoder",
    event: "update-value",
  },

  data() {
    return {
      currentDecoder: "",
      decoders: DecoderHelper.decoders,
    };
  },

  methods: {
    ...mapActions("tag", ["getDecoders"]),
  },

  computed: {
    ...mapState("tag", { dbDecoders: "decoders" }),
    localDecoder: {
      get() {
        return this.decoder;
      },

      set(val) {
        this.$emit("update-value", val);
      },
    },
  },

  async mounted() {
    await this.getDecoders();

    if (this.dbDecoders != null && this.dbDecoders.length > 0)
      this.decoders = this.dbDecoders;

    this.currentDecoder = this.decoder;
  },

  watch: {
    currentDecoder(newVal) {
      this.$emit("update-value", newVal);
    },

    decoder() {
      this.currentDecoder = this.decoder;
    },
  },
};
</script>
