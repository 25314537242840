<template>
  <v-select
    v-model="localValue"
    :items="[
      { name: 'Default', value: 0 },
      { name: 'Json', value: 1 },
      { name: 'Hex', value: 2 },
      { name: 'Base64', value: 3 },
    ]"
    item-text="name"
    item-value="value"
    :label="$t('mqtt.meta.fields.parsingTopic')"
  />
</template>

<script>
export default {
  name: "MqttParsingType",
  props: {
    mdl: {
      default: 0,
    },
  },

  model: {
    prop: "mdl",
  },

  data() {
    return {};
  },

  computed: {
    localValue: {
      get() {
        return this.mdl;
      },

      set(val) {
        this.$emit("input", val);
      },
    },
  },
};
</script>