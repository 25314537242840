<template>
  <v-container :fluid="fluid">
    <v-row>
      <v-col
        :cols="12"
        :md="6"
        :sm="12"
        class="d-flex justify-left align-center"
      >
        <h2>{{ title }}</h2>
      </v-col>
      <v-col :cols="12" :md="hasHeaderActionSlot ? 6 - actionSize : 6" :sm="12">
        <v-text-field
          v-model="search"
          :placeholder="$t('tag.search')"
          append-icon="search"
        ></v-text-field>
      </v-col>

      <v-col :cols="12" :md="actionSize" :sm="12" v-if="hasHeaderActionSlot">
        <slot name="headerAction"></slot>
      </v-col>
    </v-row>

    <v-row>
      <v-col :cols="12" :md="md" :sm="sm" v-for="(v, i) in localItems" :key="i">
        <selection-sheet
          v-model="localItem"
          :item="v"
          :color="color"
          :item-key="itemKey"
          :item-label="itemLabel"
          :font-size="fontSize"
          :icon-key="iconKey == null ? icon : v[iconKey]"
        />
      </v-col>
    </v-row>

    <v-row>
      <v-col :cols="4" class="d-flex justify-center align-center">
        <v-btn outlined @click="page = page - 1" :disabled="page <= 0"
          ><v-icon>mdi-arrow-left</v-icon>
        </v-btn>
      </v-col>

      <v-col :cols="4" class="d-flex justify-center align-center">
        <h4>{{ page + 1 }}</h4>
      </v-col>

      <v-col :cols="4" class="d-flex justify-center align-center">
        <v-btn
          outlined
          @click="page = page + 1"
          :disabled="(page + 1) * pageSize >= filteredItems.length"
          ><v-icon>mdi-arrow-right</v-icon>
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import SelectionSheet from "@/components/common/SelectionSheet";
export default {
  name: "SelectionSheetContainer",

  components: {
    SelectionSheet,
  },

  props: {
    items: {
      type: Array,
      required: true,
    },

    // NOTE: The item should be the id that we want to make the checks for
    item: {
      default: null,
      required: true,
    },

    itemKey: {
      default: "id",
      required: true,
    },

    title: {
      default: "SelectionSheet",
      required: true,
    },

    itemLabel: {
      default: "name",
    },

    pageSize: {
      type: Number,
      default: 12,
    },

    md: {
      default: 3,
    },

    sm: {
      default: 6,
    },

    actionSize: {
      default: 1,
    },

    fluid: {
      type: Boolean,
      default: true,
    },

    filterKeys: {
      type: Array,
      default: function () {
        return ["name", "id", "deveui"];
      },
    },

    color: {
      type: String,
      default: "primary",
    },

    fontSize: {
      type: String,
      default: "1rem",
    },

    icon: {
      type: String,
      default: "mdi-broadcast",
    },

    iconKey: {
      type: String,
      default: null,
    },
  },

  model: {
    prop: "item",
  },

  data() {
    return {
      page: 0,
      search: "",
    };
  },

  computed: {
    localItem: {
      get() {
        return this.item;
      },

      set(value) {
        this.$emit("input", value);
      },
    },

    localItems() {
      return this.filteredItems.slice(
        this.page * this.pageSize,
        (this.page + 1) * this.pageSize
      );
    },

    filteredItems() {
      if (this.search == "" || !this.search) return this.items;
      return this.items.filter(this.filterFunc);
    },

    hasHeaderActionSlot() {
      return !!this.$slots["headerAction"];
    },
  },

  methods: {
    filterFunc(val) {
      for (var k of this.filterKeys) {
        if (!val[k]) continue;

        if (val[k].toLowerCase().includes(this.search.toLowerCase()))
          return true;
      }

      return false;
    },
  },

  created() {
    // TODO: Find the page that the item is on if the item is not null
  },

  watch: {
    search(val, oldVal) {
      if (val != "" && val && val != oldVal) this.page = 0;
    },
  },
};
</script>