<template>
  <v-container fluid>
    <v-row>
      <h3>{{ $t("modbus.register.title") }}</h3>
    </v-row>
    <v-row>
      <modbus-register-field
        @save="saveRegister"
        :registers="localValue"
        :register="selectedRegister"
      />
    </v-row>
    <v-row>
      <v-btn
        class="mt-2"
        v-if="localValue.length > 0"
        color="primary"
        @click="pollRegisters"
        >{{ $t("modbus.register.poll") }}</v-btn
      >
      <v-data-table
        :items.sync="localValue"
        :headers="headers"
        class="mt-2"
        style="width: 100%"
        ref="dtable"
      >
        <template v-slot:[`item.register`]="{ item }">
          <v-chip>{{
            regCountByType(item.valueType) > 1
              ? item.register +
                " - " +
                (parseInt(item.register) + (regCountByType(item.valueType) - 1))
              : item.register
          }}</v-chip>
        </template>

        <template v-slot:[`item.valueType`]="{ item }">
          <v-chip>{{
            $t("modbus.register.valueTypes." + item.valueType)
          }}</v-chip>
        </template>

        <template v-slot:[`item.byteOrder`]="{ item }">
          <v-chip>{{
            $t("modbus.register.byteOrders." + item.byteOrder)
          }}</v-chip>
        </template>

        <template v-slot:[`item.modbusMasterId`]="{ item }">
          {{ getMasterNameById(item.modbusMasterId) }}
        </template>

        <template v-slot:[`item.actions`]="{ item }">
          <v-btn @click="editRegister(item)" color="primary" icon>
            <v-icon>mdi-pen</v-icon></v-btn
          >
          <v-btn @click="remove(item.modbusRegisterId)" color="error" icon>
            <v-icon>mdi-delete</v-icon></v-btn
          >
        </template>
      </v-data-table>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import modbusMixin from "../../_helpers/modbusMixin";
import ModbusRegisterField from "./ModbusRegisterField.vue";
import modbusRepository from "../../api/repositories/modbusRepository";

export default {
  name: "ModbusRegisterForm",

  mixins: [modbusMixin],

  components: {
    ModbusRegisterField,
  },

  props: {
    value: {
      type: Array,
      default: () => [],
    },
  },

  model: {
    prop: "value",
  },
  data() {
    return {
      selectedRegister: undefined,
      headers: [
        {
          text: this.$t("modbus.register.fields.master"),
          value: "modbusMasterId",
        },
        { text: this.$t("modbus.register.fields.slave"), value: "slave" },
        { text: this.$t("modbus.register.fields.key"), value: "key" },
        { text: this.$t("modbus.register.fields.register"), value: "register" },
        {
          text: this.$t("modbus.register.fields.pollInterval"),
          value: "pollInterval",
        },
        {
          text: this.$t("modbus.register.fields.valueType"),
          value: "valueType",
        },
        {
          text: this.$t("modbus.register.fields.byteOrder"),
          value: "byteOrder",
        },
        {
          text: this.$t("modbus.register.polledValue"),
          value: "polledValue",
        },
        { text: "", value: "actions" },
      ],
    };
  },

  computed: {
    ...mapGetters("modbus", ["getMasterNameById"]),

    localValue: {
      get() {
        return this.value;
      },

      set(val) {
        this.$emit("input", val);
      },
    },
  },

  methods: {
    ...mapActions("modbus", ["getMasters"]),

    remove(id) {
      var registerIdx = this.localValue.findIndex(
        (d) => d.modbusRegisterId == id
      );
      if (registerIdx === -1) return;

      this.localValue.splice(registerIdx, 1);
    },

    saveRegister(reg) {
      var idx = this.localValue.findIndex(
        (d) => d.modbusRegisterId == reg.modbusRegisterId
      );
      if (reg.modbusRegisterId != undefined && idx != -1)
        this.localValue.splice(idx, 1);
      else reg.modbusRegisterId = crypto.randomUUID();

      this.localValue.push(reg);
      this.selectedRegister = undefined;
    },

    editRegister(reg) {
      this.selectedRegister = { ...reg };
    },

    async pollRegisters() {
      var result = await modbusRepository
        .poll(this.localValue)
        .then((d) => d.data)
        .catch(() => []);
      if (result.length == 0) return;

      for (var r of result) {
        var register = this.localValue.find(
          (d) => d.modbusRegisterId == r.registerId
        );
        if (register === undefined) continue;

        register.polledValue = r.value;
      }

      var temp = this.localValue;
      this.localValue = [];
      this.$nextTick(() => {
        this.localValue = temp;
      });
    },
  },

  async created() {
    await this.getMasters();
  },
};
</script>