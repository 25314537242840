<template>
  <div class="d-flex justify-space-evenly align-center">
    <v-autocomplete
      v-model="localValue"
      :items="brokers"
      item-text="hostname"
      item-value="mqttBrokerId"
      prepend-inner-icon="mdi-access-point"
      :label="$t('mqtt.selectBroker')"
    />

    <create-broker-dialog />
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import CreateBrokerDialog from "@/components/mqtt/CreateBrokerDialog";
export default {
  components: {
    CreateBrokerDialog,
  },

  props: {
    value: {
      type: String,
      default: null,
    },
  },

  data() {
    return {};
  },

  methods: {
    ...mapActions("mqtt", ["getBrokers"]),
  },

  computed: {
    ...mapGetters("mqtt", ["brokers"]),
    localValue: {
      get() {
        return this.value;
      },

      set(val) {
        this.$emit("input", val);
      },
    },
  },

  async created() {
    await this.getBrokers();
  },
};
</script>