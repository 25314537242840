<template>
  <v-container fluid class="pa-0">
    <selection-sheet-container
      v-model="applicationId"
      :title="$t('application.choose')"
      :items="ownedApps"
      item-key="applicationId"
      :action-size="1"
      v-if="advanced"
    >
      <template v-slot:headerAction>
        <v-btn
          icon
          outlined
          fab
          color="green darken2"
          @click="dialog = !dialog"
        >
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </template>
    </selection-sheet-container>

    <v-container fluid class="pa-0 d-flex align-center justify-right" v-else>
      <v-autocomplete
        :items="applications"
        v-model="applicationId"
        :label="$t('application.choose')"
        prepend-icon="mdi-apps"
        item-value="applicationId"
        item-text="name"
      />

      <v-btn large icon fab color="green darken2" @click="dialog = !dialog">
        <v-icon>mdi-plus</v-icon>
      </v-btn>
    </v-container>

    <so-dialog v-model="dialog" v-on:accept="createAction">
      <v-card-title>
        <span class="headline">{{ $t("common.create") }}</span>
      </v-card-title>
      <v-card-text>
        <v-text-field
          :label="$t('application.fields.name')"
          name="name"
          prepend-icon="mdi-apps"
          v-model="applicationName"
        ></v-text-field>
      </v-card-text>
    </so-dialog>

    <v-row v-for="(message, i) in issues" :key="i">
      <span
        class="red--text text--lighten-1 px-3 text--center"
        style="width: 100%"
        >{{ message }}</span
      >
    </v-row>

    <span class="green--text text--lighten-1 px-3" v-if="status">{{
      status
    }}</span>
  </v-container>
</template>

<script>
import { mapActions, mapState } from "vuex";
import SoDialog from "@/components/common/SoDialog";
import SelectionSheetContainer from "../common/SelectionSheetContainer.vue";

export default {
  name: "ApplicationSelector",

  components: {
    SoDialog,
    SelectionSheetContainer,
  },

  props: {
    application: {
      default: null,
      type: Number,
    },

    advanced: {
      default: true,
    },
  },

  model: {
    prop: "application",
  },

  data() {
    return {
      dialog: false,
      applicationName: "",
      issues: [],
      status: null,
      applicationIdRule: [
        (v) =>
          !!v ||
          this.$t("validation.required", [this.$t("tag.fields.applicationId")]),
      ],
    };
  },

  computed: {
    ...mapState("companies", { applications: "companyApps" }),

    ownedApps() {
      return this.applications.filter((d) => this.owns(d.companyId));
    },

    applicationId: {
      get() {
        return this.application;
      },

      set(value) {
        this.$emit("input", value);
      },
    },
  },

  methods: {
    ...mapActions("companies", ["getCompanyApplications"]),
    ...mapActions("applications", ["createApplication"]),

    async createAction() {
      // Clear the messages to make room for some new ones!
      this.issues = [];
      this.status = null;
      if (this.applicationName == null || this.applicationName == "") {
        this.issues = [this.$t("application.validation.nameRequired")];
        return;
      }

      // Create the application
      await this.createApplication({
        applicationPayload: { name: this.applicationName },
      });

      await this.getCompanyApplications();
      this.status = this.$t("application.applicationCreated");
    },
  },

  async created() {
    await this.getCompanyApplications();
  },
};
</script>