<template>
  <v-container
    class="mt-6 rounded"
    style="background-color: rgba(0, 0, 0, 0.06)"
  >
    <v-form :lazy-validation="true" ref="registerForm">
      <v-row>
        <v-col cols="12" md="10" sm="8">
          <v-autocomplete
            :label="$t('modbus.register.fields.modbusMasterId')"
            :items="masters"
            item-value="modbusMasterId"
            item-text="name"
            prepend-inner-icon="mdi-ip"
            v-model="payload.modbusMasterId"
            :rules="rules.masterRules"
          />
        </v-col>
        <v-col cols="12" md="2" sm="4">
          <v-text-field
            v-model="payload.slave"
            type="number"
            prepend-inner-icon="mdi-server"
            :label="$t('modbus.register.fields.slave')"
            :rules="rules.slaveRules"
          />
        </v-col>
        <v-col cols="12" md="6" sm="6">
          <v-select
            :items="filteredTypes"
            item-text="title"
            item-value="value"
            :label="$t('modbus.register.fields.registerType')"
            v-model="payload.type"
          ></v-select>
        </v-col>
        <v-col cols="12" md="6" sm="6">
          <v-text-field
            type="number"
            :label="$t('modbus.register.fields.register')"
            :rules="rules.registerRules"
            prepend-inner-icon="mdi-counter"
            v-model="payload.register"
          />
        </v-col>
        <v-col cols="12" md="6" sm="6">
          <v-select
            :items="valueTypes"
            item-text="title"
            item-value="value"
            :label="$t('modbus.register.fields.valueType')"
            v-model="payload.valueType"
          />
        </v-col>
        <v-col cols="12" md="6" sm="6">
          <v-select
            :items="byteOrders"
            item-text="title"
            item-value="value"
            :label="$t('modbus.register.fields.byteOrder')"
            v-model="payload.byteOrder"
          />
        </v-col>

        <v-col cols="12" md="6" sm="6" v-if="!write">
          <v-text-field
            type="number"
            :label="$t('modbus.register.fields.pollInterval')"
            :rules="rules.pollIntervalRules"
            prepend-inner-icon="mdi-counter"
            v-model="payload.pollInterval"
          />
        </v-col>

        <v-col cols="12" md="6" sm="6" v-if="!write">
          <v-text-field
            :label="$t('modbus.register.fields.key')"
            :rules="[(v) => !!v || $t('modbus.validation.required')]"
            prepend-inner-icon="mdi-key"
            v-model="payload.key"
          ></v-text-field>
        </v-col>

        <v-col cols="12" md="12" sm="12" v-if="write">
          <v-autocomplete
            autocomplete="off"
            :label="$t('alarm.fields.tagDataKey')"
            v-model="payload.key"
            :items="keys"
            required
            item-text="label"
            item-value="key"
            prepend-icon="mdi-key"
          ></v-autocomplete>
        </v-col>

        <v-col cols="12" md="12" sm="12">
          <v-btn block text color="primary" type="button" @click="handleSubmit"
            >{{
              register?.modbusRegisterId === undefined
                ? $t("common.add")
                : $t("common.save")
            }}
          </v-btn>
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>

<script>
import { mapState } from "vuex";
import modbusMixin from "../../_helpers/modbusMixin";

export default {
  props: {
    registers: {
      type: Array,
      default: () => [],
    },

    register: {
      type: Object,
      default: () => undefined,
    },

    write: {
      type: Boolean,
      default: false,
    },

    keys: {
      type: Array,
      default: () => [],
    },
  },

  mixins: [modbusMixin],

  data() {
    return {
      payload: {
        modbusMasterId: undefined,
        slave: 1,
        type: 0,
        valueType: 0,
        byteOrder: 1,
        key: "",
        register: 1,
        pollInterval: 600,
      },

      valid: false,

      types: [
        {
          title: this.$t("modbus.register.types.0"),
          value: 0,
        },

        {
          title: this.$t("modbus.register.types.1"),
          value: 1,
        },

        {
          title: this.$t("modbus.register.types.2"),
          value: 2,
        },

        {
          title: this.$t("modbus.register.types.3"),
          value: 3,
        },
      ],

      writeIgnores: [2, 3],

      valueTypes: [
        {
          title: this.$t("modbus.register.valueTypes.0"),
          value: 0,
        },

        {
          title: this.$t("modbus.register.valueTypes.1"),
          value: 1,
        },

        {
          title: this.$t("modbus.register.valueTypes.2"),
          value: 2,
        },

        {
          title: this.$t("modbus.register.valueTypes.3"),
          value: 3,
        },

        {
          title: this.$t("modbus.register.valueTypes.4"),
          value: 4,
        },

        {
          title: this.$t("modbus.register.valueTypes.5"),
          value: 5,
        },

        {
          title: this.$t("modbus.register.valueTypes.6"),
          value: 6,
        },
      ],

      byteOrders: [
        {
          title: this.$t("modbus.register.byteOrders.0"),
          value: 0,
        },
        {
          title: this.$t("modbus.register.byteOrders.1"),
          value: 1,
        },
        {
          title: this.$t("modbus.register.byteOrders.2"),
          value: 2,
        },
        {
          title: this.$t("modbus.register.byteOrders.3"),
          value: 3,
        },
      ],

      rules: {
        slaveRules: [
          (v) => !!v || this.$t("modbus.validation.required"),
          (v) => (v && v > 0) || this.$t("modbus.validation.slaveMinVal"),
          (v) => (v && v <= 255) || this.$t("modbus.validation.slaveMaxVal"),
        ],

        masterRules: [(v) => !!v || this.$t("modbus.validation.required")],

        registerRules: [
          (v) => !!v || this.$t("modbus.validation.required"),
          (v) =>
            (v && v < 67000) || this.$t("modbus.validation.registerMaxVal"),
          (v) => (v && v >= 0) || this.$t("modbus.validation.registerMinVal"),
          (v) =>
            (v && this.canUseRegister(v)) ||
            this.$t("modbus.validation.registerAllreadyReadFrom"),
        ],

        pollIntervalRules: [
          (v) => !!v || this.$t("modbus.validation.required"),
          (v) => (v && v >= 60) || this.$t("modbus.validation.pollIntervalMin"),
        ],
      },
    };
  },

  computed: {
    ...mapState("modbus", ["masters"]),
    filteredTypes() {
      if (this.write)
        return this.types.filter((d) => !this.writeIgnores.includes(d.value));
      return this.types;
    },
  },

  methods: {
    handleSubmit() {
      if (!this.$refs.registerForm.validate()) {
        this.valid = false;
        return;
      }

      // Emit and add Action if the field is write or poll (2 == Write || 1 == Poll)
      this.$emit("save", { ...this.payload, action: this.write ? 2 : 1 });

      // Reset form
      this.$refs.registerForm.resetValidation();

      this.payload = {
        modbusMasterId: undefined,
        slave: 1,
        type: 0,
        valueType: 0,
        byteOrder: 1,
        key: "",
        register: 1,
        pollInterval: 600,
      };
    },
  },

  watch: {
    register(val) {
      if (val == undefined) return;

      this.payload = val;
    },
  },
};
</script>
