<template>
  <v-form
    :lazy-validation="false"
    @submit.prevent="save"
    v-model="valid"
    ref="form"
    class="d-flex justify-space-between align-center"
    style="width: 100%"
  >
    <v-autocomplete
      autocomplete="off"
      :label="$t('alarm.fields.tag')"
      v-model="tag"
      :items="tags"
      required
      item-text="name"
      item-value="deveui"
      prepend-icon="mdi-cellphone-link"
      @change="fetchKeys"
    ></v-autocomplete>

    <v-autocomplete
      autocomplete="off"
      :disabled="tag === '' || tag === undefined"
      :label="$t('alarm.fields.tagDataKey')"
      v-model="key"
      :items="keys"
      required
      item-text="label"
      item-value="key"
      prepend-icon="mdi-key"
    ></v-autocomplete>

    <v-text-field
      :label="$t('spotIo.fields.key') + '*'"
      name="message"
      prepend-icon="mdi-message"
      type="text"
      v-model="spotIoKey"
      :rules="spotIoKeyRule"
    ></v-text-field>

    <v-btn
      color="primary"
      type="submit"
      text
      :disabled="
        tag === '' ||
        tag === undefined ||
        key === '' ||
        key === undefined ||
        !valid
      "
      >{{ $t("common.add") }}</v-btn
    >
  </v-form>
</template>

<script>
import { mapActions, mapState } from "vuex";
export default {
  emits: ["add"],

  props: {
    spotIos: {
      type: Array,
      default: () => [],
    },

    clearTag: {
      type: Boolean,
      default: false,
    },

    clearKey: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      valid: false,
      tag: "",
      key: "",
      spotIoKey: "",
      spotIoKeyRule: [
        (v) => !!v || this.$t("spotIo.validation.spotIoKeyRequired"),
        (v) =>
          !this.spotIos
            .map((d) => d.key.toLowerCase())
            .includes(v.toLowerCase()) ||
          this.$t("spotIo.validation.spotIoKeyUnique"),
      ],
    };
  },

  computed: {
    ...mapState("tag", ["tags"]),
    ...mapState("tagData", ["keys"]),
  },

  methods: {
    ...mapActions("tag", ["getTags", "getKeysForTag"]),

    async fetchKeys() {
      if (this.tag === "" || this.tag === undefined) return; // TODO: Reset keys

      await this.getKeysForTag({ tagId: this.tag });
    },

    save() {
      this.valid = this.$refs.form.validate();
      if (!this.valid) {
        return;
      }

      this.$emit("add", {
        tagId: this.tag,
        key: this.key,
        spotIoKey: this.spotIoKey,
      });
      if (this.clearTag) this.tag = "";
      if (this.clearKey) this.key = "";
      this.spotIoKey = "";

      this.valid = true;
    },
  },

  async created() {
    await this.getTags();
  },
};
</script>