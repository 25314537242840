export default {
  methods: {
    canUseRegister(reg) {
      reg = parseInt(reg);
      let occupiedRegs = this.compileOccupiedRegisters();
      let regCount = this.regCountByType(this.payload.valueType);
      if (occupiedRegs.includes(reg)) return false;

      if (regCount > 1)
        for (var i = 1; i < regCount; i++)
          if (occupiedRegs.includes(parseInt(reg) + parseInt(i))) return false;

      return true;
    },

    compileOccupiedRegisters() {
      let regs = [];
      for (var reg of this.registers) {
        // Ignore the currently editing register
        if (
          this.register?.modbusRegisterId !== undefined &&
          reg?.modbusRegisterId != undefined &&
          this.register?.modbusRegisterId == reg?.modbusRegisterId
        )
          continue;

        regs.push(parseInt(reg.register));
        let regCount = this.regCountByType(reg.valueType);
        if (regCount > 1)
          for (var i = 1; i < regCount; i++)
            regs.push(parseInt(reg.register) + parseInt(i));
      }

      return regs;
    },

    regCountByType(type) {
      switch (type) {
        case 0: // FLOAT
          return 2;
        case 1: // DOUBLE
          return 4;
        case 2: // INT16
          return 1;
        case 3: // UINT16
          return 1;
        case 4: // INT32
          return 2;
        case 5: // UINT32
          return 2;
        case 6: // bool
          return 1;
        default:
          return 1;
      }
    },
  },
};
