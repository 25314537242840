<template>
  <v-container
    class="mt-6 rounded"
    style="background-color: rgba(0, 0, 0, 0.06)"
  >
    <v-form
      v-model="valid"
      :lazy-validation="true"
      @submit="handleSubmit"
      ref="nodeForm"
    >
      <v-row>
        <v-col cols="12" md="10" sm="8">
          <v-autocomplete
            :label="$t('opc.server.autocomplete')"
            :items="servers"
            item-value="opcServerId"
            item-text="serverUrl"
            prepend-inner-icon="mdi-ip"
            v-model="payload.serverId"
            :rules="rules.serverRules"
          />
        </v-col>

        <v-col cols="12" md="10" sm="8">
          <v-text-field
            v-model="payload.nodeId"
            prepend-inner-icon="mdi-server"
            :label="$t('opc.node.fields.nodeId')"
            :hint="$t('opc.node.hints.nodeId')"
            persistent-hint
            :rules="rules.nodeIdRules"
          />
        </v-col>

        <v-col
          cols="12"
          md="10"
          sm="10"
          v-if="
            !this.monitoringNode &&
            this.deveui !== undefined &&
            this.deveui !== ''
          "
        >
          <v-autocomplete
            autocomplete="off"
            :label="$t('alarm.fields.tagDataKey')"
            v-model="payload.key"
            :items="keys"
            required
            item-text="label"
            item-value="key"
            prepend-icon="mdi-key"
          ></v-autocomplete>
        </v-col>
        <v-col cols="12" md="12" sm="12">
          <v-btn block text color="primary" @click="handleSubmit"
            >{{
              node?.opcNodeId === undefined
                ? $t("common.add")
                : $t("common.save")
            }}
          </v-btn>
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
  name: "OpcNodeField",

  props: {
    node: {
      type: Object,
      default: () => undefined,
    },

    monitoringNode: {
      type: Boolean,
      default: false,
    },

    deveui: {
      type: String,
      default: undefined,
    },
  },

  data() {
    return {
      keys: [],
      valid: false,
      payload: {
        serverId: "",
        nodeId: "",
        monitoringNode: this.monitoringNode,
        deveui: this.deveui,
      },

      rules: {
        serverRules: [(v) => !!v || this.$t("modbus.validation.required")],
        nodeIdRules: [(v) => !!v || this.$t("opc.validation.server.required")],
      },
    };
  },

  computed: { ...mapState("opc", ["servers"]) },

  methods: {
    ...mapActions("opc", ["getServers"]),
    ...mapActions("tag", ["getKeysForTag"]),

    handleSubmit() {
      if (!this.$refs.nodeForm.validate()) {
        this.valid = false;
        return;
      }

      this.$emit("save", { ...this.payload });
      this.$refs.nodeForm.resetValidation();

      this.payload = {
        serverId: "",
        nodeId: "",
        monitoringNode: this.monitoringNode,
        deveui: this.deveui,
      };
    },
  },

  async created() {
    await this.getServers();
    if (!this.monitoringNode && this.deveui !== undefined && this.deveui !== "")
      this.keys = await this.getKeysForTag({ tagId: this.deveui });
  },

  watch: {
    async deveui() {
      if (
        !this.monitoringNode &&
        this.deveui !== undefined &&
        this.deveui !== ""
      )
        this.keys = await this.getKeysForTag({ tagId: this.deveui });
    },
  },
};
</script>
