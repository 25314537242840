<template>
  <v-container fluid>
    <v-row><h3>SpotIO's</h3></v-row>

    <v-row>
      <tag-with-key-adder @add="add" clear-key :spotIos="localValue" />
    </v-row>
    <v-row><v-divider /></v-row>

    <v-row class="d-flex flex-wrap">
      <selection-sheet
        :key="i"
        v-for="(v, i) in localValue"
        small
        :item="v"
        item-key="inheritedDeveui"
        :label="v.key"
        :sub-label="v.inheritedDeveui + ' - ' + v.inheritedKey"
        font-size=".6rem"
        deleteable
        disable
        add-margin
        @delete="() => remove(i)"
      />
    </v-row>
  </v-container>
</template>

<script>
import TagWithKeyAdder from "@/components/tag/TagWithKeyAdder";
import SelectionSheet from "../common/SelectionSheet.vue";
export default {
  components: { TagWithKeyAdder, SelectionSheet },

  props: {
    deveui: {
      type: String,
      required: true,
    },
    value: {
      default: () => [],
    },
  },

  model: { prop: "value" },

  emits: ["input"],

  data() {
    return {};
  },

  computed: {
    localValue: {
      get() {
        return this.value;
      },

      set(val) {
        this.$emit("input", val);
      },
    },
  },
  methods: {
    add(v) {
      this.localValue.push({
        tagId: this.deveui,
        key: v.spotIoKey,
        inheritedDeveui: v.tagId,
        inheritedKey: v.key,
      });
    },

    remove(idx) {
      this.localValue.splice(idx, 1);
    },
  },
  async created() {},
};
</script>