<template>
  <v-container fluid class="pa-0">
    <broker-selector v-model="val.brokerId" />

    <v-text-field
      v-model="val.edgeId"
      :label="$t('mqtt.sparkplug.meta.edgeId')"
      :hint="$t('mqtt.sparkplug.meta.hint.edgeId')"
    />

    <v-text-field
      v-model="val.groupId"
      :label="$t('mqtt.sparkplug.meta.groupId')"
      :hint="$t('mqtt.sparkplug.meta.hint.groupId')"
    />
    <v-switch
      :label="$t('mqtt.sparkplug.meta.useNameForPublish')"
      v-model="val.useNameForPublish"
    ></v-switch>
  </v-container>
</template>

<script>
import BrokerSelector from "./BrokerSelector.vue";

export default {
  name: "",

  components: { BrokerSelector },

  props: {
    value: {
      default: () => {},
    },
  },

  model: {
    prop: "value",
  },

  data() {
    return {};
  },

  computed: {
    val: {
      get() {
        return this.value;
      },

      set(val) {
        this.$emit("input", val);
      },
    },
  },

  methods: {},
};
</script>