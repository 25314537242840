<template>
  <v-container fluid>
    <v-row>
      <opc-node-field
        @save="saveNode"
        :deveui="deveui"
        :monitoring-node="monitoringNode"
      />
    </v-row>

    <v-row>
      <v-data-table
        class="mt-2"
        style="width: 100%"
        :items="value"
        :headers="headers"
      >
        <template v-slot:[`item.actions`]="{ item }">
          <v-btn @click="selectRemove(item.opcNodeId)" icon>
            <v-icon color="red">mdi-delete</v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </v-row>
  </v-container>
</template>

<script>
import OpcNodeField from "@/components/opc/OpcNodeField.vue";

export default {
  components: { OpcNodeField },
  props: {
    monitoringNode: {
      type: Boolean,
      default: false,
    },

    deveui: {
      type: String,
      default: undefined,
    },

    value: {
      type: Array,
      default: () => [],
    },
  },

  model: { prop: "value" },

  data() {
    let hdrs = [
      { text: this.$t("opc.node.fields.serverId"), value: "serverId" },
      { text: this.$t("opc.node.fields.nodeId"), value: "nodeId" },
      { text: "", value: "actions" },
    ];

    return {
      headers: hdrs,
    };
  },

  methods: {
    saveNode(payload) {
      this.localValue.push(payload);
    },

    selectRemove(id) {
      let result = this.localValue.find((d) => d.opcNodeId == id);
      if (result === undefined) return;

      this.localValue = this.localValue.filter((node) => node.opcNodeId !== id); // Only keep other nodes
    },
  },

  computed: {
    localValue: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
};
</script>
