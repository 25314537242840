<template>
  <v-dialog v-model="dialog" max-width="700">
    <template v-slot:activator="{ on }">
      <v-btn v-on="on" color="primary" class="pa-4" icon outlined v-if="!edit">
        <v-icon>{{ edit ? "mdi-pen" : "mdi-plus" }}</v-icon>
      </v-btn>
    </template>

    <v-card>
      <v-card-title>{{
        edit ? $t("mqtt.updateTitle") : $t("mqtt.createTitle")
      }}</v-card-title>
      <v-card-text>
        <div class="d-flex justify-space-evenly align-center">
          <v-text-field
            v-model="localBroker.hostname"
            :label="$t('mqtt.fields.hostname')"
            class="px-2"
          />
          <v-text-field
            type="number"
            v-model="localBroker.port"
            :label="$t('mqtt.fields.port')"
            class="px-2"
          />
        </div>
      </v-card-text>
      <v-card-text>
        <div class="d-flex justify-space-between align-center">
          <v-select
            class="px-2"
            :items="mqttVersions"
            v-model="localBroker.version"
            :label="$t('mqtt.fields.version')"
          />

          <v-select
            class="px-2"
            :items="sslmodes"
            v-model="localBroker.sslMode"
            :label="$t('mqtt.fields.sslmode')"
          />
        </div>
        <v-text-field
          v-if="localBroker.sslMode == 2"
          v-model="localBroker.CACertificate"
          :label="$t('mqtt.fields.CACertificate')"
        />
      </v-card-text>

      <v-card-text>
        <v-switch
          v-model="localBroker.useAuth"
          :label="$t('mqtt.fields.useAuth')"
        ></v-switch>
        <div
          v-if="localBroker.useAuth"
          class="d-flex justify-content-between align-center"
        >
          <v-text-field
            v-model="localBroker.username"
            :label="$t('mqtt.fields.username')"
            class="mr-2"
          />
          <v-text-field
            v-model="localBroker.password"
            :label="$t('mqtt.fields.password')"
          />
        </div>
      </v-card-text>

      <v-card-text v-if="permitted('SuperAdmin')">
        <v-switch
          v-model="localBroker.isGlobal"
          :label="$t('mqtt.fields.isGlobal')"
        ></v-switch>
      </v-card-text>

      <v-card-text>
        <v-btn :disabled="isTesting" @click="testBroker" color="primary" text>{{
          $t("mqtt.testBroker")
        }}</v-btn>
      </v-card-text>

      <v-card-actions class="pb-5">
        <v-spacer></v-spacer>
        <v-btn @click="dialog = false" color="primary" text>{{
          $t("common.cancel")
        }}</v-btn>
        <v-btn v-if="edit" @click="confirmDelete = true" color="error" text>{{
          $t("common.delete")
        }}</v-btn>

        <v-btn @click="save" color="primary">{{ $t("common.save") }}</v-btn>
      </v-card-actions>
    </v-card>
    <delete-dialog
      v-if="edit"
      :dialog="confirmDelete"
      v-on:confirm-delete="deleteSelectedBroker(localBroker.mqttBrokerId)"
      v-on:cancel-dialog="confirmDelete = false"
    />
  </v-dialog>
</template>

<script>
import { mapActions, mapState } from "vuex";
import mqttRepository from "../../api/repositories/mqttRepository.js";
import DeleteDialog from "../common/DeleteDialog.vue";

export default {
  components: {
    DeleteDialog,
  },

  props: {
    edit: {
      default: false,
      type: Boolean,
    },

    value: {
      default: () => {
        return {
          mqttBrokerId: null,
          hostname: "",
          port: 1883,
          sslMode: 0,
          version: 0,
          CACertificate: "",
          ClientCertificate: "",
          ClientKey: "",
          KeyPassPhrase: "",
          useAuth: false,
          username: "",
          password: "",
          isGlobal: false,
        };
      },
      type: Object,
    },
  },

  data() {
    return {
      dialog: false,
      confirmDelete: false,
      isTesting: false,
      sslmodes: [
        { text: this.$t("mqtt.sslmodes.0"), value: 0 },
        { text: this.$t("mqtt.sslmodes.1"), value: 1 },
        { text: this.$t("mqtt.sslmodes.2"), value: 2 },
      ],

      mqttVersions: [
        { text: this.$t("mqtt.versions.0"), value: 0 },
        { text: this.$t("mqtt.versions.1"), value: 1 },
      ],
    };
  },

  computed: {
    ...mapState("users", ["currentUser"]),
    localBroker: {
      get() {
        return this.value;
      },

      set(val) {
        this.$emit("input", val);
      },
    },
  },

  methods: {
    ...mapActions("mqtt", [
      "createBroker",
      "updateBroker",
      "getBrokers",
      "deleteBroker",
    ]),
    ...mapActions("alert", { alertSuccess: "success", alertError: "error" }),

    async save() {
      if (this.edit) {
        let result = await this.updateBroker({
          brokerId: this.localBroker.mqttBrokerId,
          payload: this.localBroker,
        });
        if (result) this.dialog = false;
        return;
      }

      let result = await this.createBroker(this.localBroker);
      if (result) {
        await this.getBrokers();
        this.dialog = false;
      }
    },

    async deleteSelectedBroker(brokerId) {
      let result = await this.deleteBroker(brokerId);

      if (result == true) {
        this.dialog = false;
        await this.getBrokers();
      }
    },

    async testBroker() {
      this.isTesting = true;
      try {
        var res = await mqttRepository.testBroker(
          this.currentUser,
          this.localBroker
        );
      } catch {
        this.alertError(this.$t("common.error"));
        this.isTesting = false;
        return;
      }

      if (res) {
        if (res.data.wasSuccessful) {
          this.alertSuccess(this.$t(res.data.message));
        } else {
          this.alertError(this.$t(res.data.message));
        }
      } else {
        this.alertError(this.$t("common.error"));
      }

      this.isTesting = false;
    },

    openDialog() {
      this.dialog = true;
    },
  },
};
</script>