<template>
  <div class="text-left" style="position: relative; overflow-x: scroll">
    <div
      class="copy-button elevation-2 rounded"
      @click="copyPayloadToClipboard"
      v-if="!hideCopy"
    >
      <v-icon small>mdi-content-copy</v-icon> Copy
    </div>
    <pre v-html="json"></pre>
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  name: "JsonDisplay",

  props: {
    value: {
      default: undefined,
      type: String,
    },

    hideCopy: {
      default: false,
    },
  },

  computed: {
    json() {
      try {
        return this.syntaxHighlight(
          JSON.stringify(JSON.parse(this.value), undefined, 2)
        );
      } catch {
        return this.value;
      }
    },

    jsonWithoutHighlighting() {
      try {
        return JSON.stringify(JSON.parse(this.value), undefined, 2);
      } catch {
        return this.value;
      }
    },
  },

  methods: {
    ...mapActions("alert", ["success", "error"]),
    async copyPayloadToClipboard() {
      let result = this.jsonWithoutHighlighting;
      let success = true;
      try {
        await navigator.clipboard.writeText(result).then(
          function () {
            success = true;
          },
          function (e) {
            success = false;
            console.error("Could not copy to clipboard", e);
          }
        );
      } catch (e) {
        console.error("Could not copy to clipboard", e);
        success = false;
      }

      if (success) this.success(this.$t("eventhub.copySuccess"));
      else this.error(this.$t("eventhub.copyError"));
    },

    syntaxHighlight(inpJson) {
      inpJson = inpJson
        .replace(/&/g, "&amp;")
        .replace(/</g, "&lt;")
        .replace(/>/g, "&gt;");
      return inpJson.replace(
        /("(\\u[a-zA-Z0-9]{4}|\\[^u]|[^\\"])*"(\s*:)?|\b(true|false|null)\b|-?\d+(?:\.\d*)?(?:[eE][+\\-]?\d+)?)/g,
        function (match) {
          var cls = "number";
          if (/^"/.test(match)) {
            if (/:$/.test(match)) {
              cls = "key";
            } else {
              cls = "string";
            }
          } else if (/true|false/.test(match)) {
            cls = "boolean";
          } else if (/null/.test(match)) {
            cls = "null";
          }
          return '<span class="' + cls + '">' + match + "</span>";
        }
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.copy-button {
  cursor: pointer;
  width: 55px;
  position: absolute;
  right: 10px;
  text-align: center;
}
</style>
